import React from 'react';
import styled from 'styled-components';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from '../../utils/constants';
import VerificationModal from './modal/VerificationModal';
import useVerification from '../../hooks/verification/useVerification';

interface IStyledProps {
  isMobile?: boolean;
}
const MuiAlert = withStyles({
  root: {
    width: '100%',
    padding: `10px 40px`,
  },
  message: {
    padding: `0px `,
    width: '100%',
    display: 'flex',
    // flexDirection: 'column',
  },
  icon: {
    padding: '12px 0 !important',
  },
})(Alert);
const VerifiedAlert = styled(MuiAlert)`` as React.ComponentType<AlertProps>;
const AlertContent = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }: IStyledProps) => (isMobile && 'column') || 'row'};
  align-items: ${({ isMobile }: IStyledProps) => (isMobile && 'flex-start') || 'center'};;
  justify-content: ${({ isMobile }: IStyledProps) => (isMobile && 'space-between') || 'flex-start'};
  //height: ${({ isMobile }: IStyledProps) => (isMobile && '100px') || 'auto'};
  width: 100%;
`;
const AlertText = styled.p<any>`
  font-size: 14px;
  margin: ${({ isMobile }) => (isMobile && '10px 0') || '0 10px'};
  padding: 0;
  font-weight: bold;
`;
const Button = styled(MuiButton)`
  cursor: pointer;
`;

const VerificationAlert = () => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  const {
    setModal,
    isPhoneVerified,
    isEmailVerified,
    unSetModal,
    showVerificationModal,
  } = useVerification();

  return (
    <VerifiedAlert severity="error">
      <AlertContent isMobile={isMobile}>
        <AlertText isMobile={isMobile}>
          {`Dear User, Kindly verify your account by,
          ${(!isEmailVerified && `Email and`) || ''}
          ${(!isPhoneVerified && `Phone number using sms`) || ''} first to have
          access to full features of application`}
        </AlertText>
        {!isPhoneVerified && (
          <Button variant="contained" color="secondary" onClick={setModal}>
            Click to Verify Phone
          </Button>
        )}
      </AlertContent>
      <VerificationModal open={showVerificationModal} handleClose={unSetModal} />
    </VerifiedAlert>
  );
};
export default VerificationAlert;

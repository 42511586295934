import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import shortId from 'shortid';
import Link from 'next/link';
import { IParentCategory } from 'src/interface';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const ChildAccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 45,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

type Props = {
  categories: IParentCategory[];
};

const MobileCategoriesNav: React.FC<Props> = ({ categories }) => {
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      {categories?.map((category) => {
        return (
          <Accordion
            key={shortId.generate()}
            square
            expanded={expanded === category.name}
            onChange={handleChange(category.name)}
          >
            <AccordionSummary
              aria-controls={`${category.name}-content`}
              id={`${category.name}-header`}
            >
              <Typography>{category.name}</Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
              {category.children.map((child) => {
                const { name, slug } = child;
                return (
                  <Link
                    key={shortId.generate()}
                    href="/[parentCategory]/[childCategory]"
                    as={`/${category.slug}/${slug}`}
                    prefetch={false}
                  >
                    <ChildAccordionSummary
                      key={shortId.generate()}
                      aria-controls={`${name}-content`}
                      id={`${name}-header`}
                    >
                      <Typography variant="body2">{name}</Typography>
                    </ChildAccordionSummary>
                  </Link>
                );
              })}
            </StyledAccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
export default MobileCategoriesNav;

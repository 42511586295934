import React from 'react';
import { SIZE } from 'baseui/input';
import { PinCode } from 'baseui/pin-code';
import { useFormikContext } from 'formik';

export interface IPinCode {
  pinCode: Array<string>;
}
const PinCodeInput = ({ value }: { value: string[] }) => {
  const { errors, setFieldValue } = useFormikContext<IPinCode>();
  return (
    <PinCode
      values={value}
      onChange={({ values }) => {
        setFieldValue('pinCode', values);
      }}
      error={errors && errors.pinCode !== undefined}
      placeholder="-"
      size={SIZE.large}
      overrides={{
        Root: {
          style: () => {
            return {
              justifyContent: 'center',
            };
          },
        },
      }}
    />
  );
};
export default PinCodeInput;

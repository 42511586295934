import React from 'react';
import styled from 'styled-components';
import shortId from 'shortid';
import Container from '@material-ui/core/Container';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from 'src/utils/constants';
import { IParentCategory } from 'src/interface';
import useGetAllCategories from 'src/hooks/api/categories/useGetAllCategories';
import MobileCategoriesNav from './MobileCategoriesNav';
import NavMenuItem from './NavMenuItem';

const NavigationContainer = styled(Container)`
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 45px;
`;
const Navigation = styled.div`
  background-color: ${({ theme }) => theme.colors.plainWhite};
`;

type IProps = {
  className?: string;
};

const CategoriesTopNavigation = ({ className }: IProps) => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  const { Categories } = useGetAllCategories();
  if (isMobile) {
    return <MobileCategoriesNav categories={Categories ?? []} />;
  }
  return (
    <>
      <Navigation className={className}>
        <NavigationContainer maxWidth="lg">
          {Categories?.map((category: IParentCategory) => {
            return <NavMenuItem key={shortId.generate()} category={category} />;
          })}
        </NavigationContainer>
      </Navigation>
    </>
  );
};

export default CategoriesTopNavigation;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import { PhoneNumberSchema } from 'src/utils/validationSchemas';
import Regex from 'src/utils/regex';
import { ITheme } from 'src/interface';

interface IStyledProps {
  isActive?: boolean;
  hasError?: boolean;
  theme?: ITheme;
}

const FormHeader = styled.div`
  margin: 0 0 24px;
`;
const TitleWraper = styled.div`
  margin: 24px 0 12px;
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
`;
const SubTitle = styled.h1`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  color: rgba(0, 47, 52, 0.64);
`;

const PhoneNumberInput = styled.div`
  position: relative;
  width: 100%;
`;

const PhoneLabel = styled.label`
  color: #002f34;
  display: block;
  font-size: 14px;
  line-height: 16px;
`;

const PhoneInputOuterWrapper = styled.div<IStyledProps>`
  background: #fff;
  box-shadow: ${(props: IStyledProps) =>
    (props.isActive && !props.hasError && `inset 0 0 0 3px ${props.theme?.colors.primary}`) ||
    (props.hasError && `inset 0 0 0 3px ${props.theme?.colors.error}`) ||
    'inset 0 0 0 1px rgba(0, 47, 52, 0.64)'};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0;
  border-style: none;
  margin: 4px 0;
  flex-wrap: wrap;
  position: relative;
`;

const PhoneInputInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const CountryCode = styled.div`
  border-left: none;
  display: block;
  border-right: 1px solid rgba(0, 47, 52, 0.36);
  color: rgba(0, 47, 52, 0.36);
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  font-size: 13px;
`;

const InputWrapper = styled.div`
  flex-grow: 1;
`;

const PhoneInput = styled.input`
  background: none !important;
  border: none !important;
  display: block !important;
  height: 48px !important;
  box-sizing: border-box !important;
  outline: none !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: 100%;
  overflow: visible;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none !important;
  text-indent: 0;
  text-shadow: none;
  font-size: 13px;
`;

const validatePhone = (val: string) => {
  return Regex.numericRegex.test(val);
};
export interface IPhoneNumber {
  phoneNumber: string;
}
const PhoneNumber = () => {
  const [isFocused, setFocus] = useState<boolean>(false);
  const inputRef = useRef(null);
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<
    IPhoneNumber
  >();
  return (
    <>
      <FormHeader>
        <TitleWraper>
          <Title>What is your phone number?</Title>
        </TitleWraper>
        <SubTitle>
          You`&apos;`ll use it for login and the usual stuff. We will send you a code to your phone
        </SubTitle>
      </FormHeader>
      <PhoneNumberInput>
        <PhoneLabel />
        <PhoneInputOuterWrapper
          isActive={values.phoneNumber?.length > 0 || isFocused}
          hasError={(errors && errors.phoneNumber !== undefined) || false}
        >
          <PhoneInputInnerWrapper>
            <CountryCode>+92</CountryCode>
            <InputWrapper>
              <PhoneInput
                id="phone"
                name="phoneNumber"
                type="text"
                placeholder="Phone Number"
                ref={inputRef}
                onChange={(e) => {
                  if (validatePhone(e.target.value) || e.target.value === '') {
                    setFieldValue('phoneNumber', e.target.value);
                  }
                }}
                onFocus={() => {
                  setFocus(true);
                }}
                onBlur={() => {
                  setFocus(false);
                  setFieldTouched('phoneNumber', true);
                }}
                value={values.phoneNumber}
              />
            </InputWrapper>
          </PhoneInputInnerWrapper>
        </PhoneInputOuterWrapper>
        <FormHelperText error>{errors && touched.phoneNumber && errors.phoneNumber}</FormHelperText>
      </PhoneNumberInput>
    </>
  );
};

PhoneNumber.label = 'Enter Phone number';
PhoneNumber.initialValues = {
  phoneNumber: '',
};
PhoneNumber.validationSchema = PhoneNumberSchema;

export default PhoneNumber;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RentableLogoEmblem = ({ fill, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 140 140" {...rest}>
    <rect id="Artboard1" x="0" y="0" width="140" height="140" fill="none" />
    <g id="Artboard11">
      <g transform="matrix(0.0454094,0,0,0.0454094,-189.934,-141.088)">
        <path
          d="M6041.15,6190.08L6041.15,5499.05C6041.15,5499.05 6046.09,5443.43 6075.76,5411.9C6105.43,5380.38 6943.57,4617.64 6943.57,4617.64C6943.57,4617.64 6963.35,4590.45 6960.87,4484.14C6958.4,4377.83 6961.09,4261.62 6961.09,4261.62L5848.3,5225.85C5848.3,5225.85 5744.46,5304.97 5628.26,5225.85L6918.84,4095.98C6918.84,4095.98 6961.3,4076.19 6961.09,3952.58C6960.87,3828.95 6961.09,3690.5 6961.09,3690.5L5504.64,4951.42C5504.64,4951.42 5410.69,5025.59 5301.91,4940.29L6931.21,3524.85C6931.21,3524.85 6961.3,3487.77 6961.09,3460.57C6960.87,3433.37 6961.09,3131.74 6961.09,3131.74C6694.81,3362.2 6428.52,3592.66 6162.24,3823.12C5895.96,4053.58 5629.68,4284.04 5363.39,4514.5C5231.85,4627.84 5100.82,4741.78 4969.5,4855.38C4901.92,4914 4834,4973.16 4764.63,5030.35C4693.5,5088.99 4599.34,5157.92 4562.5,5244.54C4523.86,5335.42 4513.4,5436.83 4559.31,5527.68C4580.25,5569.11 4615.25,5607.92 4670.21,5639.98C4670.21,5639.98 4691.22,5490.4 4804.95,5431.06C4918.69,5371.72 5089.28,5369.25 5227.73,5485.45C5366.19,5601.65 6041.15,6190.08 6041.15,6190.08ZM4488.49,4224.54L4982.97,4660.91L4785.17,4854.99L4518.16,4625.07C4518.16,4625.07 4490.96,4600.34 4488.49,4560.78C4486.02,4521.22 4488.49,4224.54 4488.49,4224.54ZM4488.49,3665.78L5304.37,4375.35L5099.17,4573.15L4505.8,4063.83C4505.8,4063.83 4488.49,4044.05 4488.49,3979.77L4488.49,3665.78ZM4488.49,3107.02L5623.31,4091.03L5408.22,4286.35L4532.66,3519.91C4532.66,3519.91 4488.49,3490.24 4488.49,3406.18L4488.49,3107.02Z"
          fill={fill}
          fillRule="nonzero"
        />
      </g>
    </g>
  </SvgIcon>
);

RentableLogoEmblem.defaultProps = {
  fill: '#3fb865',
};

export default RentableLogoEmblem;

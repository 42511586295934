import React from 'react';
import Link from 'next/link';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import shortId from 'shortid';
import { IParentCategory } from 'src/interface';
import { useRouter } from 'next/router';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledButton = styled(Button)<any>`
  padding: 10px 5px !important;
  margin: 0 5px !important;
  font-size: 13px !important;
  background: ${({ isactive, theme }) => isactive && theme.colors.primary} !important;
  color: ${({ isactive }) => isactive && 'white'} !important;
  border-radius: 0 !important;
`;
const StyledMenuItem = styled(MenuItem)<any>`
  background: ${({ isactive, theme }) => isactive && theme.colors.primary} !important;
  color: ${({ isactive }) => isactive && 'white'} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary} !important;
    color: white !important;
  }
`;
const Container = styled('div')``;

type Props = {
  category: IParentCategory;
};

const NavMenuItem = ({ category }: Props) => {
  const router = useRouter();
  const { query } = router;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const { name, children, slug } = category;
  return (
    <Container>
      <StyledButton
        isactive={query.parentCategory === slug}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {name}
        <ArrowDropDownIcon />
      </StyledButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children.length > 0 &&
          children.map((childCat) => {
            return (
              <Link
                key={shortId.generate()}
                href="/[parentCategory]/[childCategory]"
                as={`/${slug}/${childCat.slug}`}
                prefetch={false}
              >
                <StyledMenuItem isactive={query.childCategory === childCat.slug}>
                  {childCat.name}
                </StyledMenuItem>
              </Link>
            );
          })}
      </Menu>
    </Container>
  );
};
export default NavMenuItem;

import React from 'react';
import styled from 'styled-components';
import { Check } from 'baseui/icon';

const FormHeader = styled.div`
  margin: 0 0 24px;
`;
const TitleWraper = styled.div`
  margin: 24px 0 12px;
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
`;
const SubTitle = styled.h1`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  color: rgba(0, 47, 52, 0.64);
`;
const CheckIcon = styled(Check)`
  background: ${({ theme }) => theme.colors.primary};
`;
const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const VerificationStatus = () => {
  return (
    <Section>
      <FormHeader>
        <TitleWraper>
          <Title>
            Verification completed{' '}
            <CheckIcon
              size={22}
              overrides={{
                Svg: {
                  style: () => {
                    return {
                      borderRadius: '50px',
                      color: 'white',
                      alignSelf: 'center',
                    };
                  },
                },
              }}
            />
          </Title>
        </TitleWraper>
        <SubTitle>Thank you for verification</SubTitle>
      </FormHeader>
    </Section>
  );
};
VerificationStatus.initialValues = {};
VerificationStatus.label = 'Verification complete';

export default VerificationStatus;

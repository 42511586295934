import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from 'next/link';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useMediaQuery } from '@material-ui/core';

import { BREAKPOINTS } from 'src/utils/constants';
import useVerification from 'src/hooks/verification/useVerification';
import { useAuthDispatch, useAuthState } from 'src/contexts/auth/auth.context';
import UserDropdown from './components/UserDropdown';
import VerificationAlert from '../Verification/VerificationAlert';
import CategoriesNavigation from './components/CategoriesNavigation';
import RentableLogo from '../../assets/svgs/RentableLogo';
import RentableLogoEmblem from '../../assets/svgs/RentableLogoEmblem';

type IStyledProps = {
  isOpen?: boolean;
  isMobile?: boolean;
};

const HeaderContainer = styled.header`
  .google-auto-placed {
    display: none;
  }
`;
const TopBar = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.secondary};
`;

const Navigation = styled.nav`
  background-color: ${({ theme }) => theme.colors.plainWhite};
`;
const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;
const StyledButton = styled(Button)`
  margin: 0 5px !important;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
`;
export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const BurgerMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
`;
const BurgerIcon = styled.div<IStyledProps>`
  width: 30px;
  height: 3px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  ${(props) => {
    if (props.isOpen) {
      return `
      transform: translateX(-50px);
      background: transparent;
      box-shadow: none;
      `;
    }
    return `
        box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
        background: #3fb865;
      `;
  }}
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
  }
  &:before {
    ${(props) => {
      if (props.isOpen) {
        return `
          transform: rotate(45deg) translate(35px, -35px);    
      `;
      }
      return `
          transform: translateY(-8px);     
      `;
    }}
  }
  &:after {
    ${(props) => {
      if (props.isOpen) {
        return `
        transform: rotate(-45deg) translate(35px, 35px);
      `;
      }
      return `
          transform: translateY(8px);     
      `;
    }}
  }
`;
export const StyledRentableLogoEmblem = styled(RentableLogoEmblem)`
  width: 40px !important;
  height: 40px !important;
`;
export const StyledRentableLogo = styled(RentableLogo)`
  width: 150px !important;
  height: 40px !important;
`;

const Header = () => {
  const router = useRouter();
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  const dispatch = useAuthDispatch();
  const { isAuthenticated } = useAuthState();

  const { showVerificationAlert } = useVerification();

  const [showMenu, setShowMenu] = React.useState(false);

  const handleMenuChange = (val: boolean) => {
    setShowMenu(val);
  };

  const handleClickAway = () => {
    setShowMenu(false);
  };

  const setLoginModal = useCallback(
    (show: boolean) => {
      dispatch({ type: 'SWITCH_AUTH_MODAL', payload: show });
    },
    [dispatch]
  );

  const PostAdHandler = useCallback(() => {
    if (isAuthenticated) {
      router.push('/post-listing');
    } else {
      setLoginModal(true);
    }
  }, [isAuthenticated, setLoginModal, router]);

  useEffect(() => {
    handleClickAway();
  }, [router.query]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <HeaderContainer>
        <TopBar>
          <Grid item direction="column" justify="space-around" alignItems="center">
            {showVerificationAlert && <VerificationAlert />}
            <Container maxWidth="lg">
              <TopSection>
                <Link href="/" prefetch={false}>
                  <LogoSection>
                    <StyledRentableLogoEmblem />
                    {!isMobile && <StyledRentableLogo />}
                  </LogoSection>
                </Link>
                <RightSection className="post-ad-ab">
                  <StyledButton variant="contained" color="primary" onClick={PostAdHandler}>
                    Post Ad
                  </StyledButton>
                  {!isAuthenticated && (
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() => setLoginModal(true)}
                    >
                      Login
                    </StyledButton>
                  )}
                  {isAuthenticated && <UserDropdown />}
                  {isMobile && (
                    <BurgerMenu onClick={() => handleMenuChange(!showMenu)}>
                      <BurgerIcon isOpen={showMenu} />
                    </BurgerMenu>
                  )}
                </RightSection>
              </TopSection>
            </Container>
            {(showMenu || !isMobile) && <CategoriesNavigation />}
          </Grid>
        </TopBar>
        <Navigation />
      </HeaderContainer>
    </ClickAwayListener>
  );
};
export default Header;

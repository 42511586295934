import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import styled from 'styled-components';
import PinCodeInput, { IPinCode } from '../PinCodeInput';
import { IPhoneNumber } from './PhoneNumber';

const FormHeader = styled.div`
  margin: 0 0 24px;
`;
const TitleWraper = styled.div`
  margin: 24px 0 12px;
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
`;
const SubTitle = styled.h1`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  color: rgba(0, 47, 52, 0.64);
`;
const ResendText = styled(FormHelperText)`
  font-size: 12px !important;
  margin: 20px !important;
  cursor: pointer;
`;

interface IProps {
  retryCode: (phoneNumber: string) => void;
}
type ResendCode = {
  handleReSendCode: () => Promise<any>;
};
const ResendCode = ({ handleReSendCode }: ResendCode) => {
  const [count, setCount] = useState(200);
  const [isResendActive, setIsResendActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (count === 0) {
        setIsResendActive(true);
      } else if (!isResendActive) {
        setCount(count - 1);
      }
    }, 1000);
  }, [count, isResendActive]);
  if (isResendActive) {
    return (
      <ResendText
        onClick={async () => {
          await handleReSendCode();
          setIsResendActive(false);
          setCount(200);
        }}
      >
        Click to resend code
      </ResendText>
    );
  }
  return <ResendText>{`Resend code in ${count} seconds`}</ResendText>;
};
const PinCodeVerification = (props: IProps) => {
  const { retryCode } = props;
  const { values, setSubmitting } = useFormikContext<IPhoneNumber & IPinCode>();

  const handleReSendCode = useCallback(async () => {
    setSubmitting(true);
    await retryCode(values.phoneNumber);
    setSubmitting(false);
  }, [values.phoneNumber, retryCode, setSubmitting]);

  return (
    <>
      <FormHeader>
        <TitleWraper>
          <Title>Enter the code we have sent</Title>
        </TitleWraper>
        <SubTitle>{`We have sent a code to ${values.phoneNumber}`}</SubTitle>
      </FormHeader>
      <PinCodeInput value={values.pinCode} />
      <ResendCode handleReSendCode={handleReSendCode} />
    </>
  );
};

PinCodeVerification.label = 'Verify Pin code';
PinCodeVerification.initialValues = {
  pinCode: ['', '', '', '', ''],
};
PinCodeVerification.validate = (values: IPinCode) => {
  const errors: {
    [key: string]: string;
  } = {};
  if (values.pinCode.includes('')) {
    errors.pinCode = 'Enter 5 digits code you received';
  } else {
    delete errors.pinCode;
  }
  return errors;
};
export default PinCodeVerification;

import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from 'src//utils/constants';
import PhoneVerificationForm from '../form/VerificationForm';
import Modal from '../../Common/modal';

const ModalContent = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile && '0px') || '30px'};
  height: 100%;
`;
const StyledModal = styled(Modal)``;

interface IProps {
  handleClose: () => void;
  open: boolean;
}
const VerificationModal = ({ open, handleClose }: IProps) => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);

  return (
    <StyledModal title="Verification" open={open} handleClose={handleClose} contentWidth="400px">
      <ModalContent isMobile={isMobile}>
        <PhoneVerificationForm handleClose={handleClose} />
      </ModalContent>
    </StyledModal>
  );
};
export default VerificationModal;

import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Step, StepLabel, Stepper, StepperProps, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BREAKPOINTS } from 'src/utils/constants';
import useVerification from 'src/hooks/verification/useVerification';
import { useAuthDispatch } from 'src/contexts/auth/auth.context';
import PhoneNumber from './steps/PhoneNumber';
import PinCodeVerification from './steps/PinCodeVerification';
import VerificationStatus from './steps/VerificationStatus';

interface IStyledProps {
  isMobile?: boolean;
  loading?: boolean;
}
interface IVerificationProps {
  phoneNumber: string;
  pinCode: string[];
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Section = styled('section')<IStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ loading }) => (loading && 'center') || 'flex-start'};
  align-items: center;
  flex-grow: 1;
`;
const ButtonContainer = styled.div`
  display: flex;
  margin: 30px 0;
`;
const StyledButton = styled(Button)`
  font-size: 13px !important;
`;
const StyledStepper = styled(Stepper)<IStyledProps & StepperProps>`
  padding: ${({ isMobile }) => (isMobile && '20px 0px 0px 0px') || 'inherit'};
`;

const StyledStep = styled(Step)`
  .MuiStepLabel-label {
    font-size: 13px !important;
  }
  .MuiStepIcon-text {
    font-size: 13px !important;
  }
  .MuiStepIcon-completed,
  .MuiStepIcon-active {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;
const Loader = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.primary};
`;

const steps: Array<any> = [PhoneNumber, PinCodeVerification, VerificationStatus];

interface IProps {
  handleClose: () => void;
}

const VerificationForm = ({ handleClose }: IProps) => {
  const { tempNumber, getVerificationCode, verifyCode } = useVerification();
  const dispatchToAuth = useAuthDispatch();

  const [activeStep, setActiveStep] = useState<number>((tempNumber && 1) || 0);
  const [stepLoading, setStepLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery<string>(BREAKPOINTS.MOBILE);

  const isLastStep = (): boolean => {
    return activeStep === steps.length - 1;
  };

  const handlePrev = (): void => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  const handleNext = useCallback(() => {
    return [setActiveStep(Math.min(activeStep + 1, steps.length - 1))];
  }, [activeStep]);

  const onSubmit = async (values: IVerificationProps, formikBag: FormikHelpers<any>) => {
    setStepLoading(true);
    if (!isLastStep()) {
      const { phoneNumber } = values;
      if (activeStep === 0) {
        formikBag.setSubmitting(true);
        const success = await getVerificationCode(phoneNumber);
        if (success) {
          handleNext();
        }
        setStepLoading(false);
        formikBag.setSubmitting(false);
      } else if (activeStep === 1) {
        if (!values.pinCode.includes('')) {
          const success = await verifyCode(values.pinCode.join(''));
          if (success) {
            handleNext();
          }
          setStepLoading(false);
        } else {
          // For not steping to next step
          setActiveStep(1);
        }
      }
    } else {
      const { phoneNumber } = values;
      dispatchToAuth({
        type: 'UPDATE_PHONE_NUMBER',
        payload: phoneNumber,
      });
      handleClose();
    }
  };

  const initValues = steps.reduce(
    (values, { initialValues }) => ({
      ...values,
      phoneNumber: tempNumber,
      ...initialValues,
    }),
    {}
  );
  const ActiveStep = steps[activeStep];
  const { validationSchema } = ActiveStep;
  const validation = ActiveStep.validate;

  return (
    <Formik
      initialValues={initValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validate={validation}
    >
      {(formikHelpers) => {
        return (
          <StyledForm>
            <StyledStepper isMobile={isMobile} alternativeLabel activeStep={activeStep}>
              {steps.map((step, index) => (
                <StyledStep key={index}>
                  <StepLabel>{steps[index].label}</StepLabel>
                </StyledStep>
              ))}
            </StyledStepper>

            {steps.map((step, index) => {
              const Component = steps[index];
              return (
                activeStep === index && (
                  <Section loading={stepLoading} key={index}>
                    {(stepLoading && <Loader />) || <Component retryCode={getVerificationCode} />}
                  </Section>
                )
              );
            })}
            {!isMobile && (
              <ButtonContainer>
                <StyledButton
                  disabled={activeStep === 0 || activeStep === 2 || stepLoading}
                  onClick={handlePrev}
                >
                  Previous
                </StyledButton>
                <StyledButton
                  disabled={stepLoading || !formikHelpers.isValid || !formikHelpers.dirty}
                  type="submit"
                >
                  {isLastStep() ? 'Finish' : 'Next'}
                </StyledButton>
              </ButtonContainer>
            )}
            {isMobile && (
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    disabled={stepLoading || !formikHelpers.isValid || !formikHelpers.dirty}
                    type="submit"
                  >
                    {isLastStep() ? 'Finish' : 'Next'}
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handlePrev}
                    disabled={activeStep === 0 || activeStep === 2 || stepLoading}
                  >
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            )}
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default VerificationForm;

import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { useAuthentication } from 'src/contexts/auth/auth.context';
import { verifyPhoneCode, verifyPhoneNumber } from 'src/api/auth';
import { useNotifier } from 'src/contexts/notification/notification.context';
import useUser from '../auth/useUser';
import { getCookie, setCookie } from '../../utils/helpers';

const useVerification = () => {
  const [authState] = useAuthentication();
  const { user } = useUser();
  const { errorAlerts, errorAlert, successAlert } = useNotifier();
  const { isAuthenticated, auth } = authState;

  const [alert, setAlert] = useState(true);
  const [verificationModal, setVerificationModal] = useState(false);
  const tempNum = getCookie('tempNumber');
  const [tempNumber, setTempNumber] = useState<string | null>(tempNum);

  const setModal = () => {
    setVerificationModal(true);
  };
  const unSetModal = () => {
    setVerificationModal(false);
  };

  useEffect(() => {
    if (user?.verified_by_sms && user?.verified_by_email) {
      if (alert) {
        setAlert(false);
      }
    }
  }, [alert, user?.verified_by_sms, user?.verified_by_email]);

  useEffect(() => {
    if (tempNumber) {
      setCookie('tempNumber', tempNumber, null);
    }
  }, [tempNumber]);

  const getVerificationCode = async (phoneNumber: string) => {
    const throttledCall = throttle(async () => {
      try {
        const result = await verifyPhoneNumber({ accessToken: auth?.token || '', phoneNumber });
        // const result = { status: 200, data: { message: 'Demo' } };
        if (result.status === 200) {
          setTempNumber(phoneNumber);
          successAlert(result.data.message);
          return true;
        }
        if (result.data.errors) {
          errorAlerts(Object.values(result.data.errors));
        } else {
          errorAlerts(result.data.message);
        }
        return false;
      } catch (error) {
        errorAlert('Looks like an error happened');
        console.log(error);
        return false;
      }
    });
    return throttledCall();
  };
  const verifyCode = async (code: string) => {
    const throttledCall = throttle(async () => {
      try {
        const result = await verifyPhoneCode({ accessToken: auth?.token || '', code });
        // const result = { status: 200, data: { message: 'Demo' } };
        if (result.status === 200) {
          setCookie('tempNumber', null, null);
          successAlert(result.data.message);
          return true;
        }
        if (result.data.errors) {
          errorAlerts(Object.values(result.data.errors));
        } else {
          errorAlerts(result.data.message);
        }
        return false;
      } catch (error) {
        errorAlert('Looks like an error happened');
        console.log(error);
        return false;
      }
    });
    return throttledCall();
  };

  return {
    isPhoneVerified: user?.verified_by_sms || false,
    isEmailVerified: user?.verified_by_email || false,
    showVerificationAlert: alert && isAuthenticated,
    showVerificationModal: verificationModal,
    tempNumber,
    setModal,
    unSetModal,
    getVerificationCode,
    verifyCode,
  };
};

export default useVerification;

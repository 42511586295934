import React from 'react';
import Router from 'next/router';
import Image from 'next/image';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import useUser from 'src/hooks/auth/useUser';

const ImageContainer = styled(Avatar)`
  img {
    cursor: pointer;
    /* margin-left: -5px; */
  }
`;
const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 30px !important;
`;

const UserDropdown: React.FC = () => {
  const { user, logout } = useUser();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    logout();
    Router.push('/');
  };
  return (
    <>
      {(user?.image &&
        (user.image.includes('graph.facebook.com') ||
          user.image.includes('assets-rentable.sgp1.digitaloceanspaces.com')) && (
          <ImageContainer onClick={handleClick}>
            <Image
              width={40}
              height={40}
              src={user.image || ''}
              alt={`${user.first_name} ${user.last_name}`}
              loading="eager"
              priority
            />
          </ImageContainer>
        )) || (
        <ImageContainer
          style={{ margin: '0 10px' }}
          alt={`${user?.first_name} ${user?.last_name}`}
          src={user?.image}
          aria-controls="UserMenu"
          aria-haspopup="true"
          onClick={handleClick}
        />
      )}

      <Menu
        id="UserMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => Router.push('/dashboard')}>
          <StyledListItemIcon>
            <DashboardOutlinedIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="Dashboard" />
        </MenuItem>
        <MenuItem onClick={() => Router.push('/dashboard/userlistings')}>
          <StyledListItemIcon>
            <ListAltOutlinedIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="My Listings" />
        </MenuItem>
        <MenuItem onClick={() => Router.push('/dashboard/favourite-listings')}>
          <StyledListItemIcon>
            <StarsOutlinedIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="Favourite listings" />
        </MenuItem>
        <MenuItem onClick={() => Router.push('/dashboard/rental-agreements')}>
          <StyledListItemIcon>
            <BallotOutlinedIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="Rental Agreements" />
        </MenuItem>
        <MenuItem onClick={() => Router.push('/dashboard/profile-settings')}>
          <StyledListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="Settings" />
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <StyledListItemIcon>
            <ExitToAppOutlinedIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};
export default UserDropdown;
